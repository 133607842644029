import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: '/',
    name: 'HOME',
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'blocks',
    name: 'Blocks',
    type: 'link',
    icon: 'apps'
  },
  {
    state: 'block-types',
    name: 'Block Types',
    type: 'link',
    icon: 'category'
  },
  {
    state: 'apps',
    name: 'Your Apps',
    type: 'link',
    icon: 'apps'
  }
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu) {
    MENUITEMS.push(menu);
  }
}
