import { Routes } from '@angular/router';
import { AdminLayoutComponent, AuthLayoutComponent } from './core';
import { AuthGuard } from './core/authentication/authentication.guard';

export const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'blocks',
        loadChildren: './blocks/blocks.module#BlocksModule'
      },
      {
        path: 'categories',
        loadChildren: './categories/categories.module#CategoriesModule'
      },
      {
        path: 'block-types',
        loadChildren: './block-types/block-types.module#BlockTypesModule'
      },
      {
        path: 'apps',
        loadChildren: './apps/apps.module#AppsModule'
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './session/session.module#SessionModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
