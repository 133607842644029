const firebase_settings = { timestampsInSnapshots: true };
const firebase_config = {
  apiKey: 'AIzaSyCHxkjiUyvbM2LQ7jKHcNIXsRM1OFATe24',
  authDomain: 'intellective-marvel.firebaseapp.com',
  databaseURL: 'https://intellective-marvel.firebaseio.com',
  projectId: 'intellective-marvel',
  storageBucket: 'intellective-marvel.appspot.com',
  messagingSenderId: '1054631578888'
};

export { firebase_settings, firebase_config };
